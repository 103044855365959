<template>
  <div class="topNav">
    <img src="../../assets/images/home/Frame.svg" alt="">
    <div>
      <a-tooltip content="Already had an account?" position="br">
        <a-button type="primary" shape="round" @click="$router.push('/login')" v-if="current === 1">Log In</a-button>
      </a-tooltip>
    </div>
  </div>
  <div class="change-pwd">
    <div class="change-title">Application For Registeration</div>
    <div class="steps">
      <a-steps type="navigation" :current="current">
        <a-step>Register an Account</a-step>
        <a-step>Set Your Address</a-step>
        <a-step>Account Audit</a-step>
        <a-step>Application Result</a-step>
      </a-steps>
      <div class="steps-cont" v-if="current === 1">
        <a-form :model="formResgiter" layout="vertical" ref="firstForm">
          <a-form-item field="first_name" label="First Name" :rules="[
            {
              required: true,
              message: 'Please enter firstname.'
            },
            { maxLength: 35, minLength: 2, message: '2-35 characters.' }
          ]">
            <a-input placeholder="Required 2-35 characters" autocomplete="off" v-model="formResgiter.first_name" />
          </a-form-item>
          <a-form-item field="last_name" label="Last Name" :rules="[
            {
              required: true,
              message: 'Please enter lastname.'
            },
            { maxLength: 35, minLength: 2, message: '2-35 characters.' }
          ]">
            <a-input placeholder="Required 2-35 characters" autocomplete="off" v-model="formResgiter.last_name" />
          </a-form-item>
          <a-form-item field="phone" label="Phone"
            :rules="[{ required: true, message: 'Please enter cellphone.' }, { minLength: 10, maxLength: 15, message: 'required 10-15 characters' }]">
            <a-input placeholder="Required 10-15 characters" autocomplete="off" v-model="formResgiter.phone" />
          </a-form-item>
          <a-row>
            <a-col :span="14">
              <a-form-item field="email" label="User ID" class="email-input" :rules="resetRules.email">
                <a-input placeholder="Please enter email" autocomplete="off" v-model="formResgiter.email" />
              </a-form-item>
            </a-col>
            <a-col :span="10">
              <a-form-item>
                <div class="code-btn">
                  <a-popconfirm content="The email address has been registered!" position="right" type="warning"
                    :popup-visible="popupvisible" ok-text="Log In" cancel-text="Cancel" @ok="confirm"
                    @cancel="cancelConfirm" :content-style="{ width: '276px' }">
                    <a-button type="primary" :disabled="codFlag" @click="getCode" :loading="codeLoading">Get Email Code
                    </a-button>
                  </a-popconfirm>
                  <div class="second" :style="{ display: dispalyCode }">{{ codeTime }}s</div>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item field="email_code" label="Email Code" :rules="[
            { validator: validataCode }
          ]">
            <a-input v-model="formResgiter.email_code" autocomplete="off" placeholder="Please enter" />
          </a-form-item>
          <a-form-item field="password" label="Password" :rules="[
            { validator: validatePassword }
          ]" :validate-trigger="['blur', 'input', 'change']">
            <a-popover position="right" title="Format">
              <a-input-password placeholder="Please enter" autocomplete="new-password" v-model="formResgiter.password"
                allow-clear />
              <template #content>
                <div v-show="letterStatus === false">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#1D2129" />
                  </svg>
                  Uppercase and Lowercase Letters
                </div>
                <div v-show="letterStatus === true" style="color:#00B42A;">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#00B42A" />
                  </svg>
                  Uppercase and Lowercase Letters
                </div>
                <div v-show="numStatus === false">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#1D2129" />
                  </svg>
                  Numeric Character
                </div>
                <div v-show="numStatus === true" style="color:#00B42A;">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#00B42A" />
                  </svg>
                  Numeric Character
                </div>
                <div v-show="other === false">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#1D2129" />
                  </svg>
                  Special Character
                </div>
                <div v-show="other === true" style="color:#00B42A;">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#00B42A" />
                  </svg>
                  Special Character
                </div>
                <div v-show="length === false">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#1D2129" />
                  </svg>
                  At Least Eight Characters
                </div>
                <div v-show="length === true" style="color:#00B42A;">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#00B42A" />
                  </svg>
                  At Least Eight Characters
                </div>
              </template>
            </a-popover>
          </a-form-item>
          <a-form-item field="password_confirmation" label="Comfirm Password" :rules="[
            { validator: validateConfirm }
          ]" :validate-status="confirmStatus">
            <a-input-password v-model="formResgiter.password_confirmation" autocomplete="new-password"
              placeholder="Please enter" allow-clear />
          </a-form-item>
          <a-form-item field="company_name" label="Company Name" :rules="[
            { maxLength: 35, minLength: 2, message: '2-35 characters.' }
          ]">
            <a-input placeholder="Optional 2-35 characters" autocomplete="off" v-model="formResgiter.company_name" />
          </a-form-item>
          <a-form-item field="company_type" label="Company Type">
            <a-radio-group v-model="formResgiter.company_type" type="button">
              <a-radio v-for="val, k in companyType" :key="k" :value="k">{{ val }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item>
            <div class="next-btn">
              <a-button type="primary" shape="round" @click="firstNext" :loading="firstLoading">Next</a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <div class="steps-cont second-steps" v-if="current === 2">
        <a-card :style="{ width: '839px' }" title="Account Information">
          <template #extra>
            <a-tooltip content="Edit" position="bottom">
              <a-button shape="circle" @click="goEdit">
                <icon-edit />
              </a-button>
            </a-tooltip>
          </template>
          <a-descriptions :title="formResgiter.email" :column="2">
            <a-descriptions-item label="Name :">
              {{ formResgiter.first_name }} {{ formResgiter.last_name }}
            </a-descriptions-item>
            <a-descriptions-item label="Phone :">
              {{ formResgiter.phone }}
            </a-descriptions-item>
            <a-descriptions-item label="Company Name :">
              {{ formResgiter.company_name }}
            </a-descriptions-item>
            <a-descriptions-item label="Company Type :">
              {{ formResgiter.company_type === '1' ? 'Commercial' : 'Individual' }}
            </a-descriptions-item>
          </a-descriptions>
        </a-card>
        <div class="addressForm">
          <a-form :model="formAddress" layout="vertical" ref="secondForm">
            <a-form-item field="street1" label="Street Line 1" :rules="[
              { required: true, message: 'Street line1 is required.' },
              { maxLength: 35, minLength: 4, message: '4-35 characters.' }
            ]">
              <a-input v-model="formAddress.street1" autocomplete="off" placeholder="Required 4-35 characters" />
            </a-form-item>
            <a-form-item field="street2" label="Street Line 2"
              :rules="[{ maxLength: 35, minLength: 1, message: '1-35 characters.' }]">
              <a-input v-model="formAddress.street2" autocomplete="off" placeholder="Optional 1-35 characters" />
            </a-form-item>
            <a-form-item field="zip" label="Zip" :rules="[
              {
                required: true,
                type: 'number',
                message: 'Zip code is required.'
              },
              { minLength: 5, maxLength: 10, message: '5-10 characters' }
            ]">
              <a-config-provider :locale="enUS">
                <a-select allow-search placeholder="Required 5-10 characters" v-model="formAddress.zip"
                  @search="fromZipSearch" :loading="fromLoading" allow-clear autocomplete="off" :options="fromOptions">
                </a-select>
              </a-config-provider>
            </a-form-item>
            <a-form-item field="city" label="City" :rules="[
              { required: true, message: 'City is required' },
              { maxLength: 35, minLength: 2, message: '2-35 characters.' }
            ]">
              <a-input v-model="formAddress.city" autocomplete="off" placeholder="Required 2-35 characters" />
            </a-form-item>
            <a-form-item field="state" label="State" :rules="[
              { required: true, message: 'State is required' },
            ]">
              <a-select v-model="formAddress.state" placeholder="Please select">
                <a-option v-for="item in state" :key="item" :value="item.code">{{ item.code }} - {{ item.name }}
                </a-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <div class="next-btn">
                <a-button type="primary" shape="round" @click="secondNext">Next</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="steps-result" v-if="current === 3">
        <img src="../../assets/images/login/result-icon.svg" alt="">
        <div class="main-res">Registration is complete!</div>
        <div class="fu-res">
          <div>The application for registeration is under review,</div>
          <div>
            We will send the registration result to your email address within 1 ～ 3 business days
          </div>
        </div>
        <a-space>
          <a-button type="primary" @click="$router.push('/login')">OK</a-button>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
// 引入api中的接口
import { registerFirst, getcode, registerSecond } from '@/api/account.js'
import { commmonInfo } from '@/api/homepage.js'
import { Message } from '@arco-design/web-vue'
import local from '@/utils/local.js'
import { getZipCode } from '@/api/order.js'
import { IconEdit } from '@arco-design/web-vue/es/icon'
// 公共信息
async function getConfig () {
  const data = await commmonInfo()
  local.set('configInfo', JSON.stringify(data.data))
}
getConfig()
const companyType = JSON.parse(local.get('configInfo')).company_type
const state = JSON.parse(local.get('configInfo')).state
const router = useRouter()
const current = ref(1)
const codFlag = ref(false)
const dispalyCode = ref('none')
const codeTime = ref(30)
const formResgiter = reactive({
  first_name: '',
  last_name: '',
  phone: '',
  phone_extension: '',
  email: '',
  email_code: '',
  password: '',
  password_confirmation: '',
  company_name: '',
  company_type: '1'
})
// 验证邮箱
const resetRules = {
  email: [
    {
      validator: (value, callback) => {
        const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
        if (!value) {
          callback(new Error('Please enter.').message)
        } else if (!emailReg.test(value)) {
          callback(new Error('Please enter correct email format.').message)
        } else {
          callback()
        }
      }
    }
  ]
}
// 验证Email Code
function validataCode (value, callback) {
  if (!value) {
    callback(new Error('Please enter email code.').message)
  } else {
    callback()
  }
}
// 获取验证码
const popupvisible = ref(false)
const codeLoading = ref(false)
async function getCode () {
  firstForm.value.validateField('email', async (value) => {
    if (!value) {
      codeLoading.value = true
      const msg = await getcode({
        type: 'register',
        email: formResgiter.email
      })
      if (msg.code === 0) {
        Message.success('Email code send success.')
        codeLoading.value = false
      } else if (msg.code === 4) {
        popupvisible.value = true
        codeLoading.value = false
        return
      } else {
        codeLoading.value = false
        Message.error(msg.message)
        return
      }
      codFlag.value = true
      dispalyCode.value = 'block'
      const timeId = setInterval(() => {
        let timeCode = codeTime.value
        timeCode--
        codeTime.value = timeCode
        if (codeTime.value < 1) {
          clearInterval(timeId)
          codFlag.value = false
          codeTime.value = 30
          dispalyCode.value = 'none'
        }
      }, 1000)
    }
  })
}
const confirmStatus = ref('')
// 验证确认密码
function validateConfirm (value, callback) {
  if (!value) {
    callback(new Error('Enter your new password again.').message)
  } else if (value !== formResgiter.password) {
    callback(new Error('Passwords not match.').message)
  } else {
    callback()
    confirmStatus.value = 'success'
  }
}
const letterStatus = ref(false)
const numStatus = ref(false)
const other = ref(false)
const length = ref(false)
// 验证密码
function validatePassword (value, callback) {
  let upperCase = (/^.*[A-Z]+.*$/).test(value)
  let lowerCase = (/^.*[a-z]+.*$/).test(value)
  let hasNumber = (/^.*[0-9]+.*$/).test(value)
  let hasOther = (/^.*[^0-9A-Za-z]+.*$/).test(value)
  if (!value) {
    callback(new Error('Password is required.').message)
  } else {
    if (!upperCase || !lowerCase) {
      callback(new Error('Uppercase and Lowercase Letters.').message)
      letterStatus.value = false
    } else {
      letterStatus.value = true
    }
    if (!hasNumber) {
      callback(new Error('Numeric Character.').message)
      numStatus.value = false
    } else {
      numStatus.value = true
    }
    if (!hasOther) {
      callback(new Error('Special Character.').message)
      other.value = false
    } else {
      other.value = true
    }
    if (value.length < 8) {
      callback(new Error('At least Eight Characters.').message)
      length.value = false
    } else {
      length.value = true
    }
  }
}
const firstForm = ref(null)
const firstKey = ref(null)
const firstLoading = ref(false)
const firstNext = () => {
  firstForm.value.validate(async (value) => {
    if (!value) {
      firstLoading.value = true
      const msg = await registerFirst({
        first_name: formResgiter.first_name,
        last_name: formResgiter.last_name,
        phone: formResgiter.phone,
        phone_extension: '',
        email: formResgiter.email,
        email_code: formResgiter.email_code,
        password: formResgiter.password,
        password_confirmation: formResgiter.password_confirmation,
        company_name: formResgiter.company_name,
        company_type: formResgiter.company_type
      })
      if (msg.code === 0) {
        firstLoading.value = false
        firstKey.value = msg.data.key
        current.value = 2
      } else {
        firstLoading.value = false
        Message.error(msg.message)
      }
    }
  })
}
const confirm = () => {
  popupvisible.value = false
  router.push('/login')
}
const cancelConfirm = () => {
  popupvisible.value = false
}
const goEdit = () => {
  current.value = 1
}
const formAddress = reactive({
  street1: '',
  street2: '',
  zip: '',
  city: '',
  state: 'NJ'
})
const fromLoading = ref(false)
const fromOptions = ref([])
const fromZipSearch = async (value) => {
  if (value.length > 2) {
    fromLoading.value = true
    const msg = await getZipCode({
      term: value
    })
    if (msg.data.length > 0) {
      fromOptions.value = []
      fromLoading.value = false
      for (let i = 0; i < msg.data.length; i++) {
        fromOptions.value.push(msg.data[i].zip)
      }
    } else {
      fromLoading.value = false
      fromOptions.value = []
    }
  }
}
const secondForm = ref(null)
const secondLoading = ref(false)
const secondNext = async () => {
  secondForm.value.validate(async (value) => {
    if (!value) {
      secondLoading.value = true
      const msg = await registerSecond({
        key: firstKey.value,
        street1: formAddress.street1,
        street2: formAddress.street2,
        zip: formAddress.zip,
        city: formAddress.city,
        state: formAddress.state
      })
      if (msg.code === 0) {
        secondLoading.value = false
        current.value = 3
      } else {
        secondLoading.value = false
        Message.error(msg.message)
      }
    }
  })
}
</script>

<style lang="less" scoped>
.topNav {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 82px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.change-pwd {
  margin-top: 66px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .change-title {
    color: var(--color-text-1);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .steps {
    margin-top: 48px;
    width: 920px;

    .steps-cont {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 48px;

      .arco-form {
        width: 360px;
      }
    }
  }
}

.code-btn {
  margin-top: 22px;
  width: 100%;
  position: relative;

  .arco-btn {
    width: 100%;
    border-radius: 0 12px 12px 0;
  }
}

.email-input .arco-input-wrapper {
  border-radius: 12px 0 0 12px;
}

.second {
  color: var(--color-text-4);
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  position: absolute;
  right: -40px;
  top: 7px;
}

:deep(.arco-form-item-label-required-symbol) {
  display: none;
}

.next-btn {
  text-align: center;
  width: 100%;
}

.second-steps {
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.addressForm {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.steps-result {
  text-align: center;
  margin-top: 52px;

  .main-res {
    color: var(--color-text-1);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
    line-height: 22px;
  }

  .fu-res {
    color: var(--color-text-3);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 48px;
    margin-top: 12px;
  }
}

:deep(.arco-steps-mode-navigation .arco-steps-item:not(:last-child) .arco-steps-item-content::after),
:deep(.arco-steps-mode-navigation .arco-steps-item-active::after) {
  right: -16px;
}

:deep(.arco-steps-item) {
  overflow: visible;
}
</style>
